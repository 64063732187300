import React, { useEffect, useState } from 'react';

import http from 'libs/http';
import { toast } from 'react-toastify';
import Button from 'components/Button';
import { useDispatch, useSelector } from 'react-redux';
import { loaderActions } from 'store/loader/loader.store';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const Downloads = () => {

  const dispatch: any = useDispatch();
  const loading: boolean = useSelector((state: any) => state.loaderStore.loading);
  const [downloads, setDownloads] = useState([]);

  const getDownloads = async (): Promise<any> => {
    try {
      dispatch(loaderActions.setLoading(true));
      const { data } = await http.get('/benefit/transactions/jobs/');
      setDownloads(data.jobs);
      dispatch(loaderActions.setLoading(false));
    } catch (e: any) {
      toast.error(e.data);
      dispatch(loaderActions.setLoading(false));
    }
  }

  const downloadHandler = async (key: string) => {
    http({
      url: `benefit/transactions/jub_export?key=${key}`,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "hob-provider-transactions.xlsx");
      document.body.appendChild(link);
      link.click();
    });
  }

  useEffect((): void => {
    getDownloads();
  }, []);


  return (
    <ul className="my-9 space-y-4 relative">
      {downloads.length > 0 && <p className="text-sm tracking-wide">Preuzimanja su aktivna 10 minuta od trenutka aktivacije</p>}
      {/*{loading && <ElementLoader size="regular" color="primary" classNames="absolute left-0 top-0 flex items-center justify-center h-[70vh]"/>}*/}
      {downloads.length > 0 && downloads.map((download: any, i: number) => (
        <li key={download.key}
            className="w-full  bg-white space-y-2 font-medium shadow-sm rounded-lg p-6 animation duration-200 ease-in-out">
          <p>{i + 1}. {download.key}</p>
          <div className="flex items-center justify-between gap-x-4 rounded-md">
            <div className={`w-full bg-gray-300 rounded-md h-6 relative text-white text-center`}>
              <p
                className="z-10 relative">{download.percent < 100 ? `${Math.round(download.percent)}%` : 'Preuzeto'}</p>
              <div style={{width: `${download.percent}%`}}
                   className={`absolute left-0 top-0 h-6 bg-gradient-to-r from-base-500 to-base-600 flex items-center justify-center rounded-md ${download.percent < 100 && 'rounded-r-none'}`}></div>
            </div>
            {download.percent === 100 &&
              <Button
                bg="primary"
                className="w-10"
                size="small"
                onClick={() => downloadHandler(download.key)}
              >
                <FontAwesomeIcon icon={faDownload} className=""/>
              </Button>
            }
          </div>
        </li>
      ))}
      {!loading && downloads.length === 0 &&
          <div className="h-[70vh] text-gray-600 flex items-center justify-center">
              <div className="text-center space-y-4">
                  <FontAwesomeIcon icon={faDownload} className="text-4xl"/>
                  <p className="font-medium">Trenutno nema aktivnih preuzimanja!</p>
              </div>
          </div>
      }
    </ul>
  );
};

export default Downloads;