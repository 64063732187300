import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import TransactionApi from './transaction.api';
import {loaderActions} from "store/loader/loader.store";

const api: TransactionApi = new TransactionApi();


interface TransactionState {
  transactions: any[];
  nextPage: number | null;
  searchString: string;
  ordering: string;
  count: string | number;
  dateFrom: string;
  dateTo: string;
  status: string;
}

const initialState: TransactionState = {
  transactions: [],
  nextPage: null,
  searchString: '',
  ordering: '+',
  count: -1,
  dateFrom: '',
  dateTo: '',
  status: '',
};


const transactionSlice: any = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    setTransactions: (state: any, action: PayloadAction<any, any>): any => {
      const rawTransactions: any[] = [...state.transactions, ...action.payload];

      const uniqueTransactionsMap: Map<any, any> = new Map();

      rawTransactions.forEach(transaction => {
        if (transaction && transaction.id) {
          uniqueTransactionsMap.set(transaction.id, transaction);
        }
      });

      state.transactions = Array.from(uniqueTransactionsMap.values());
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.searchString = action.payload;
    },
    setStatus: (state: any, action: PayloadAction<string, any>): any => {
      state.status = action.payload;
    },
    setDateFrom: (state: any, action: PayloadAction<string, any>): any => {
      state.dateFrom = action.payload;
    },
    setDateTo: (state: any, action: PayloadAction<string, any>): any => {
      state.dateTo = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.ordering = action.payload;
    },
    resetTransactions: (state: any): any => {
      state.transactions = [];
    },
  },
});


export const getTransactions = (isFetchMore: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().transactionStore.nextPage;
      const searchString = getState().transactionStore.searchString;
      const dateFrom = getState().transactionStore.dateFrom;
      const dateTo = getState().transactionStore.dateTo;
      const status = getState().transactionStore.status;

      let url: string = `/v2/transactions-provider/?search=${searchString}&date_from=${dateFrom}&date_to=${dateTo}&status=${status}`;

      if (searchString || dateFrom || dateFrom || status) dispatch(transactionActions.resetTransactions());

      if (!searchString && !dateFrom && !dateTo && !status && nextPage) {
        url = nextPage;
      }


      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getTransactions(url);
      dispatch(transactionActions.setNextPage(data.next));
      dispatch(transactionActions.setCount(data.count));
      dispatch(transactionActions.setTransactions(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const transactionActions: any = transactionSlice.actions;

export default transactionSlice.reducer;
