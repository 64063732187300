import React, { useEffect } from 'react';


import Router from './Router';
import { useSelector } from 'react-redux';
import { scrollToTop } from 'libs/helpers';
import {Location, useLocation} from 'react-router-dom';
import Sidebar from './components/Sidebar/index';
import Modal from './components/Modal';
import 'react-tooltip/dist/react-tooltip.css';
import 'react-datepicker/dist/react-datepicker.css';
import Header from 'components/Header/index';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalLoader } from 'components/Loaders';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";




const disabledSidebarRoutes = ['/login', "/forgot-password","/reset-password", "/reset-password/success",  '/register'];


const App: React.FC = () => {


  const location: Location<any> = useLocation();
  const showSidebar: boolean = !disabledSidebarRoutes.includes(location.pathname);
  const isLoading: boolean = useSelector((state: any) => state.loaderStore.loading);
  const user: boolean = useSelector((state: any) => state.authStore.user);
  const isOpenSidebar: boolean = useSelector((state: any) => state.sidebarStore.isOpen);


  useEffect(() => {
    scrollToTop();
  }, [location]);


  return (
    <React.Fragment>
      {showSidebar && user && <Header />}
      {showSidebar && user && <Sidebar/>}
      {isLoading && <GlobalLoader />}
      <main className={`max-w-7xl mx-auto animation duration-200 ease-in-out`}>
        <div className="ml-[130px] lg:ml-[113px] mr-5 lg:mr-[40px] mt-14">
          <Router />
        </div>
      </main>
      {/* FOOTER */}
      <Modal />
      <ToastContainer
        position="bottom-right"
        transition={Slide}
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}


export default App;
