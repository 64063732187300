import React from 'react';
import Input from 'components/Input';
import SearchableDropdown from 'components/SearchableDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { benefitActions } from 'modules/benefit/services/benefit.store';
import { faDownLong, faGrip, faListUl, faUpLong } from '@fortawesome/free-solid-svg-icons';
import {useDispatch, useSelector} from 'react-redux';
import CheckBox from 'components/Checkbox';

const sortOptions = [
  { label: 'Popularnosti', value: 'bought'},
  { label: 'Datumu', value: 'added'},
  { label: 'Nazivu', value: 'name'},
  { label: 'Ceni', value: 'price'},
]


const Filters = () => {

  const dispatch = useDispatch();
  const filters: any = useSelector((state: any) => state.benefitStore.filters);

  const searchStringHandler = (payload: string): void => {
    dispatch(benefitActions.setSearchString(payload));
  };

  const orderingHandler = (payload: string): void => {
    dispatch(benefitActions.setOrdering(payload));
  };

  // const sortByHandler = (payload: string): void => {
  //   dispatch(benefitActions.setSortBy(payload));
  // };

  const sortByHandler = async (payload: string | null): Promise<any> => {
    dispatch(benefitActions.resetBenefits());
    await dispatch(benefitActions.setCount(0));
    await dispatch(benefitActions.setNextPage(null));
    if (payload) {
      dispatch(benefitActions.setSortBy(payload));
    } else {
      dispatch(benefitActions.setSortBy(''));
    }
  };

  const previewTypeHandler = (payload: string): void => {
    dispatch(benefitActions.setPreviewType(payload));
  };

  const onlyActiveHandler = async (value: boolean): Promise<void> => {
    dispatch(benefitActions.setOnlyActive(value));
  }

  return (
    <section className="mt-6 flex items-center justify-between">
      <div className="flex items-center gap-x-3">
        <div className="lg:w-[322px]">
          <Input
            type='text'
            isSearch
            height='regular'
            debounceTime={400}
            placeholder=" Pretraži benefite po nazivu"
            onChange={searchStringHandler}
          />
        </div>
        <div className="w-1/2">
          <CheckBox
            title="Prikaži samo aktivne benefite"
            size="regular"
            value={filters.onlyActive}
            textClassNames="font-medium text-gray-500"
            onChange={onlyActiveHandler}
          />
        </div>
      </div>
      <div className="flex items-center gap-x-2">
        <div className="w-48">
          <SearchableDropdown
            value={sortOptions.find((o: any) => o.value === filters.sortBy)}
            idField="value"
            valueToShow="label"
            placeholder="Sortiraj po"
            options={sortOptions}
            onClear={() => sortByHandler('')}
            onChange={(order: any) => sortByHandler(order.value)}
          />
        </div>
        <div className="flex items-center justify-center gap-x-2">
          <div
            className="relative w-full z-0 flex justify-end items-center rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
              onClick={() => orderingHandler('-')}
              type="button"
              className={`h-10 w-full md:w-9 rounded-l-md border border-gray-300 ${filters.ordering === '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faDownLong}/>
            </button>
            <button
              onClick={() => orderingHandler('')}
              type="button"
              className={`h-10 w-full md:w-9 items-center mx-auto rounded-r-md border border-gray-300 ${filters.ordering !== '-' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faUpLong}/>
            </button>
          </div>
          <div
            className="relative z-0 hidden md:flex justify-end items-end rounded-md md:ml-auto mt-2 lg:mt-0 shadow-xl">
            <button
              onClick={() => previewTypeHandler('grid')}
              type="button"
              className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-l-md border border-gray-300 ${filters.previewType === 'grid' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faGrip}/>
            </button>
            <button
              onClick={() => previewTypeHandler('list')}
              type="button"
              className={`h-10 w-12 md:w-9 relative flex items-center justify-center text-lg p-1 rounded-r-md border border-gray-300 ${filters.previewType === 'list' ? 'bg-base-600 text-white' : 'bg-white text-gray-500 hover:bg-gray-50'} text-sm font-medium focus:z-10 focus:outline-none focus:ring-1 focus:ring-base-500 focus:border-base-500 animation duration-200 ease-in-out`}>
              <FontAwesomeIcon icon={faListUl}/>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Filters;