import React, { useEffect, useRef } from 'react';

import { toast } from 'react-toastify';
import Button from 'components/Button';
import Filters from 'modules/benefit/UI/Filters';
import GridView from 'modules/benefit/UI/GridView';
import ListView from 'modules/benefit/UI/ListView';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import { modalActions } from 'store/modal/modal.store';
import { ConfirmDeleteDialog } from 'components/Dialogs';
import {faIcons, faPlus} from '@fortawesome/free-solid-svg-icons';
import AddBenefitForm from 'modules/benefit/UI/AddBenefitForm';
import EditBenefitForm from 'modules/benefit/UI/EditBenefitForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { benefitActions, deleteFixedBenefit, getBenefits } from 'modules/benefit/services/benefit.store';
import {useNavigate} from 'react-router-dom';

const Benefits = () => {

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const count: any = useSelector((state: any) => state.benefitStore.count, shallowEqual);
  const nextPage: any = useSelector((state: any) => state.benefitStore.nextPage, shallowEqual);
  const benefits: any = useSelector((state: any) => state.benefitStore.benefits, shallowEqual);
  const filters: any = useSelector((state: any) => state.benefitStore.filters, shallowEqual);

  const countRef = useRef(count);
  const nextPageRef = useRef(nextPage);

  useEffect(() => {
    nextPageRef.current = nextPage;
  }, [nextPage]);

  useEffect(() => {
    countRef.current = count === benefits.length;
  }, [count, benefits]);

  const openAddEmployeeForm = (benefit: any = null) => {
    let modalContent: React.JSX.Element = (
      <AddBenefitForm copyData={benefit} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: 'Dodaj benefit',
          size: 'regular',
        },
      })
    );
  };

  const openEditEmployeeForm = (benefit: any, isPreview: boolean) => {
    let modalContent: React.JSX.Element = (
      <EditBenefitForm benefit={benefit} isPreview={isPreview} />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          title: isPreview ? 'Pregled benefita' : 'Ažuriraj benefit',
          size: 'regular',
        },
      })
    );
  };

  const fetchMoreFixedBenefits = async (isFetchMore: boolean): Promise<void> => {
    try {
      await dispatch(getBenefits(isFetchMore));
    } catch (e: any) {
      console.log(e, 'Err');
    }
  };

  const openDeletePrompt = (benefitId: number): void => {
    let modalContent: React.JSX.Element = (
      <ConfirmDeleteDialog
        title="Izbriši benefit"
        description="Da li zaista želiš da izbrišeš benefit?"
        onDelete={() => deleteFixedBenefitHandler(benefitId)}
      />
    );

    dispatch(
      modalActions.openModal({
        modalContent,
        modalProps: {
          closable: false,
          size: 'small',
        },
      })
    );
  };

  const deleteFixedBenefitHandler = (benefitId: any): void => {
    try {
      dispatch(deleteFixedBenefit(benefitId));
      dispatch(modalActions.closeModal());
      toast.success('Uspešno ste izbrisali benefit!');
    } catch (e: any) {
      dispatch(modalActions.closeModal());
      toast.error('Nešto nije u redu!');
    }
  };

  const stripHtmlTags = (input: string): string => {
    if (input) return input.replace(/<\/?[^>]+(>|$)/g, "");
    else return input;
  };

  const parseCategoryName = (name: any) => {
    if (name) return name.split('/')[1];
    return name;
  };

  useEffect(() => {
    return () => {
      dispatch(benefitActions.setNextPage(null));
      dispatch(benefitActions.setCount(0));
      dispatch(benefitActions.resetBenefits());
    }
  }, []);

  useEffect(() => {
    dispatch(benefitActions.resetBenefits());
    fetchMoreFixedBenefits(false);
  }, [filters.searchString, filters.sortBy, filters.ordering]);

  useEffect(() => {
    dispatch(benefitActions.resetBenefits());
    fetchMoreFixedBenefits(false);
  }, [filters.onlyActive]);

  useEffect(() => {
    const handleScroll = () => {
      let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight > document.documentElement.offsetHeight - 20;
      if (bottomOfWindow && nextPageRef.current && !countRef.current) {
        fetchMoreFixedBenefits(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(benefitActions.setCount(0));
    dispatch(benefitActions.setNextPage(null));
    dispatch(benefitActions.resetBenefits());
  }, []);

  return (
    <>
      <section className="flex items-center gap-x-5">
        <div>
          <Button
            bg="primary"
            className="lg:w-[302px]"
            size="regular"
            onClick={() => openAddEmployeeForm()}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2"/>
            Dodaj benefit
          </Button>
        </div>
      </section>

      <Filters />

      {filters.previewType === 'grid' &&
        <GridView
          benefits={benefits}
          onCopy={(benefit: any) => openAddEmployeeForm(benefit)}
          onEdit={(benefit: any) => openEditEmployeeForm(benefit, false)}
          onPreview={(benefit: any) => navigate(`/benefits/${benefit.id}`)}
          onDelete={(benefitId: any) => openDeletePrompt(benefitId)}
        />
      }

      {filters.previewType === 'list' &&
        <ListView
          benefits={benefits}
          onCopy={(benefit: any) => openAddEmployeeForm(benefit)}
          onEdit={(benefit: any) => openEditEmployeeForm(benefit, false)}
          onPreview={(benefit: any) => navigate(`/benefits/${benefit.id}`)}
          onDelete={(benefitId: any) => openDeletePrompt(benefitId)}
        />
      }

      {benefits.length === 0 &&
        <div className="h-[55vh] flex items-center justify-center text-gray-600 text-sm font-medium">
          <div className="text-center space-y-4">
            <FontAwesomeIcon icon={faIcons} className="text-4xl"/>
            <p className="font-medium">Nema pronađenih benefita!</p>
          </div>
        </div>
      }
    </>
  );
};

export default Benefits;