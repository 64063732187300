import { createSlice } from '@reduxjs/toolkit';

import BenefitApi from './benefit.api';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loaderActions } from 'store/loader/loader.store';
import {setHeaders} from "libs/http";

const api: BenefitApi = new BenefitApi();

interface benefitState {
  categories: any[];
  providers: any[];
  company: any;
  benefits: any[];
  benefit: any;
  nextPage: number | null;
  count: string | number;
  filters: {
    searchString: string;
    status: any;
    sortBy: any;
    ordering: string;
    onlyActive: boolean;
    previewType: string;
  }
}

const initialState: benefitState = {
  categories: [],
  providers: [],
  company: null,
  benefits: [],
  benefit: null,
  nextPage: null,
  count: -1,
  filters: {
    searchString: '',
    status: '',
    sortBy: 'added',
    ordering: '-',
    onlyActive: true,
    previewType: 'grid',
  }
};

const benefitSlice: any = createSlice({
  name: 'benefit',
  initialState,
  reducers: {
    setCategories: (state: any, action: PayloadAction<any, any>): any => {
      state.categories = action.payload;
    },
    setProviders: (state: any, action: PayloadAction<any, any>): any => {
      const rawProviders: any[] = [...state.providers, ...action.payload];
      const uniqueProvidersMap: Map<any, any> = new Map();
      rawProviders.forEach(provider => {
        uniqueProvidersMap.set(provider.id, provider);
      });
      state.providers = Array.from(uniqueProvidersMap.values());
    },
    setBenefits: (state: any, action: PayloadAction<any, any>): any => {
      const rawBenefits: any[] = [...state.benefits, ...action.payload];

      const uniqueBenefitsMap: Map<any, any> = new Map();

      rawBenefits.forEach(benefit => {
        if (benefit && benefit.id) {
          uniqueBenefitsMap.set(benefit.id, benefit);
        }
      });

      state.benefits = Array.from(uniqueBenefitsMap.values());
    },
    setBenefit: (state: any, action: PayloadAction<any, any>): any => {
      state.benefit = action.payload;
    },
    setCompany: (state: any, action: PayloadAction<any, any>): any => {
      state.company = action.payload;
    },
    setNextPage: (state: any, action: PayloadAction<any, any>): any => {
      state.nextPage = action.payload;
    },
    setCount: (state: any, action: PayloadAction<any, any>): any => {
      state.count = action.payload;
    },
    resetBenefits: (state: any): any => {
      state.benefits = [];
    },
    resetCategories: (state: any): any => {
      state.categories = [];
    },
    resetProviders: (state: any): any => {
      state.providers = [];
    },
    setSearchString: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.searchString = action.payload;
    },
    setOrdering: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.ordering = action.payload;
    },
    setSortBy: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.sortBy = action.payload;
    },
    setPreviewType: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.previewType = action.payload;
    },
    setOnlyActive: (state: any, action: PayloadAction<string, any>): any => {
      state.filters.onlyActive = action.payload;
    },
    resetFilters: (state: any, action: PayloadAction<string, any>): any => {
      state.filters = {
        searchString: '',
        status: '',
        sortBy: '',
        ordering: '',
      }
    },
  },
});

export const getCompany = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const companyId = getState().authStore.user.company.id;
      const { data } = await api.getCompany(companyId);
      dispatch(benefitActions.setCompany(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const getBenefits = (isFetchMore: boolean) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const nextPage = getState().benefitStore.nextPage;
      const searchString = getState().benefitStore.filters.searchString;
      const ordering = getState().benefitStore.filters.ordering;
      const sortBy = getState().benefitStore.filters.sortBy;
      const onlyActive = getState().benefitStore.filters.onlyActive;
      const user = getState().authStore.user;

      let url = `/v2/admin/benefits/?providers=${user.company.id}&only_active=${onlyActive}`;


      if (searchString) {
        dispatch(benefitActions.resetBenefits());
        url += `&search=${searchString}`
      }
      if (sortBy) {
        url += `&ordering=${ordering}${sortBy}`
      }

      if (isFetchMore && nextPage) {
        url = nextPage;
      }

      dispatch(loaderActions.setLoading(true));
      const { data } = await api.getBenefits(url);
      dispatch(benefitActions.setNextPage(data.next));
      dispatch(benefitActions.setCount(data.count));
      dispatch(benefitActions.setBenefits(data.results));
      dispatch(loaderActions.setLoading(false));
      return Promise.resolve(data);
    } catch (e: any) {
      dispatch(loaderActions.setLoading(false));
      return Promise.reject(e);
    }
  }
}

export const getBenefit = (benefitId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.getBenefit(benefitId);
      dispatch(benefitActions.setBenefit(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const addBenefit = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'Content-type': 'multipart/form-data'});
      const { data } = await api.addBenefit(payload);
      await setHeaders({'Content-type': 'application/json'});
      dispatch(benefitActions.resetBenefits());
      dispatch(benefitActions.setNextPage(0));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const editFixedBenefit = (benefitId: number, payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({'Content-type': 'multipart/form-data'});
      const { data } = await api.editBenefit(benefitId, payload);
      await setHeaders({'Content-type': 'application/json'});
      dispatch(benefitActions.resetBenefits());
      dispatch(benefitActions.setNextPage(0));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject({e: 'Nesto je'});
    }
  }
}
export const editPromo = (benefitId: number, payload: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.editBenefit(benefitId, payload);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject({e: 'Nesto je'});
    }
  }
}

export const deleteImage = (imageId: number) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteImage(imageId);
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const uploadImages = (payload: FormData) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      await setHeaders({ 'content-type': 'multipart/form-data' });
      const { data } = await api.uploadImages(payload);
      await setHeaders({ 'content-type': 'application/json' });
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const deleteFixedBenefit = (benefitId: any) => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.deleteFixedBenefit(benefitId);
      dispatch(benefitActions.resetBenefits());
      dispatch(benefitActions.setNextPage(0));
      dispatch(getBenefits(false));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}

export const getCategories = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    try {
      const { data } = await api.getCategories();
      dispatch(benefitActions.setCategories(data));
      return Promise.resolve(data);
    } catch (e: any) {
      return Promise.reject(e);
    }
  }
}


export const benefitActions: any = benefitSlice.actions;

export default benefitSlice.reducer;
