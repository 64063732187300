import React, { useState } from 'react';

import Input from 'components/Input';
import Button from 'components/Button';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { ElementLoader } from 'components/Loaders';
import {modalActions} from 'store/modal/modal.store';
import { useDispatch, useSelector } from "react-redux";
import { requestCode } from 'modules/cart/services/cart.store';

const SendHobIdForm = ({ onSuccess }: any) => {

  const cart: any = useSelector((state: any) => state.cartStore.cart);

  const {register, handleSubmit, formState: { errors, isValid, touchedFields } }: any = useForm({
    mode: 'onBlur',
    defaultValues: {
      id: '',
    },
  });

  const dispatch: any = useDispatch();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    if (isValid) {
      let payload: any = {
        id: data.id,
        benefitIds: cart.map((b: any) => b.id),
      };

      try {
        const data = await dispatch(requestCode(payload));
        if (data.userExists === false) {
          toast.error('HoB korisnik pod upisanim ID brojem ne postoji!');
          return;
        }
        onSuccess(payload.id);
        toast.success('Kod je poslat korisniku!');
      } catch (e: any) {
        toast.error(e.data.error);
      }
    }
  };

  const closeForm = (): void => {
    dispatch(modalActions.closeModal());
  };

  return(
    <>
      <div className="relative">
        <div className="p-7 space-y-7">
          <div>
            <Input
              type='number'
              label="HoB ID korisnika"
              autoFocus
              height='medium'
              placeholder="Unesi HoB ID korisnika"
              isTouched={touchedFields.id}
              error={errors.id}
              validationRules={register('id', {
                required: {
                  value: true,
                  message: "Obavezno polje",
                },
              })}
              onChange={() => null}
            />
          </div>
        </div>
        <div
          className="flex items-center justify-between gap-x-5 sticky bottom-0 left-0 px-7 py-6 border-t w-full bg-white">
          <Button
            bg="white"
            className="w-full flex items-center justify-center gap-x-2"
            size="regular"
            onClick={closeForm}
          >
            Otkaži
          </Button>
          <Button
            bg="primary"
            className="w-full gap-x-2 relative"
            size="regular"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            Pošalji zahtev
            {loading && <ElementLoader color="white" size="small" classNames="flex items-center justify-end pr-2"/>}
          </Button>
        </div>
      </div>
    </>
  );
}

export default SendHobIdForm;