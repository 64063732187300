import http from 'libs/http';

class BenefitApi {

  getBenefits = (url: string) => {
    return http.get(url);
  };

  getBenefit = (benefitId: number) => {
    return http.get(`/v2/benefit/${benefitId}/`);
  };

  addBenefit = (payload: any) => {
    return http.post('/v2/admin/benefits/', payload);
  };

  editBenefit = (benefitId: number, payload: any) => {
    return http.patch(`/v2/admin/benefits/${benefitId}/`, payload);
  };

  uploadImages = (payload: any) => {
    return http.post('v2/admin/benefit-picture/', payload);
  };

  deleteImage = (imageId: number) => {
    return http.delete(`v2/admin/benefit-picture/${imageId}`);
  };

  deleteFixedBenefit = (benefitId: any) => {
    return http.patch(`/v2/admin/benefits/${benefitId}/`, { hide: true });
  };

  getCompany = (companyId: number) => {
    return http.get(`/v2/admin/companies/${companyId}`);
  };

  getCategories = () => {
    return http.get(`/v2/category/`);
  };

}


export default BenefitApi;