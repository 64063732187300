import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faEnvelope,
  faPencil,
  faPhone,
  faTrashCan,
  faUser,
  faUserXmark
} from "@fortawesome/free-solid-svg-icons";
import {Tooltip} from "react-tooltip";
import {useSelector} from "react-redux";
import {monetaryFormatter} from "libs/monetary";

interface ListViewProps {
  benefits: any[];
  onAddToCart: (benefit: any) => void;
  onRemoveFromCart: (benefitId: any) => void;
}

const ListView = ({ benefits, onAddToCart, onRemoveFromCart }: ListViewProps) => {

  const user: any = useSelector((state: any) => state.authStore.user);
  const cart: any = useSelector((state: any) => state.cartStore.cart);
  const loading: any = useSelector((state: any) => state.loaderStore.loading);

  const checkIsInCart = (id: number) => {
    const isInCart = cart.find((benefit: any) => benefit.id === id);
    return !!isInCart;
  }

  return (
    <ul className={`my-9 space-y-4 w-full`}>
      {benefits && benefits.length > 0 && benefits.map((benefit: any) => (
        <li key={benefit.id} className="group bg-white hover:bg-gray-50 flex items-center justify-between gap-x-2 shadow-sm rounded-lg px-5 py-3 transform hover:scale-[1.02] animation duration-200 ease-in-out">
          <div className="flex items-center gap-x-3">
            <div className="rounded-md overflow-hidden border">
              {benefit.pictures[0] &&
                <img
                  className="inline-block h-16 w-32"
                  src={benefit.pictures[0].image}
                  alt="cover"
                />
              }
              {!benefit.pictures[0] &&
                <img
                  className="inline-block h-16 w-32 transform scale-75"
                  src="/hob-logo.png"
                  alt="cover"
                />
              }
            </div>
            <div>
              <p className="font-black">{ benefit.name }</p>
              <p className="text-gray-500">{ benefit.id }</p>
            </div>
          </div>
          <div className="flex items-center justify-between gap-x-8 mr-7">
            <p className="font-black text-base-500 animation duration-200 ease-in-out mr-3">{ monetaryFormatter(+benefit.price) } { benefit.currency }</p>
            <div>
              {!checkIsInCart(benefit.id) &&
                  <FontAwesomeIcon
                      icon={faCartShopping}
                      className={`text-gray-500 hover:text-base-500 text-2xl cursor-pointer transform hover:scale-110 animation duration-200 ease-in-out`}
                      onClick={() => onAddToCart(benefit)}
                  />
              }
              {checkIsInCart(benefit.id) &&
                  <FontAwesomeIcon
                      icon={faCartShopping}
                      className={`text-base-500 text-xl cursor-pointer`}
                      onClick={() => onRemoveFromCart(benefit.id)}
                  />
              }
            </div>
          </div>
        </li>
      ))}
    </ul>
  )
};

export default ListView;